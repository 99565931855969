﻿nav {
	background: $yellow;
	overflow-x: auto;
	max-width: 100%;
	border-top: .2em solid $yellow;
	font-size: .85em;
	font-family: 'Oswald', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	text-transform:uppercase;

	@media (min-width:$screen-md-min) {
		padding: 0 9999em;
		margin: 0 -9999em;
		font-size: 1em;
	}

	ul {
		list-style-type: none;
		display: block;
		padding: 0;
		margin: 0;
		white-space: nowrap;

		li {
			display: inline-block;

			&.active {
				background: #fff;

				a {
					color: $brand-color;
					font-weight: bold;
				}
			}
		}

		a {
			display: block;
			color: rgba(0,0,0,.8);
			padding: 1.5em 1em;
			white-space: nowrap;

			@include on-event(true) {
				text-decoration: none;
			}

			@media (min-width:$screen-md-min) {
				padding: .5em 1em;
			}
		}
	}


	+ nav {
		border-top: 0;
		margin-top: -.1em;
		background: #fff;
		border-top: 0;
		border-bottom: .2em solid $yellow;

		ul li a {
			color: $brand-color;
		}
	}
}
