﻿// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
} 

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;
  
  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

body {
	@media (min-width:$screen-md-min) {
		max-width: 80vw;
		margin: 0 auto;
		overflow-x:hidden;
	}

	@media (min-width:$screen-lg-min) {
		max-width: 60vw;
		margin: 0 auto;
	}

	> *:not(:empty) {
		margin-top: 2em;

		+ nav + nav {
			margin-top: 0;
		}
	}
}

header {
	margin-left: 1em;
	margin-right: 1em;

	@media (min-width:$screen-md-min) {
		margin-left: 4em;
		margin-right: 4em;
	}
}

img {
	max-width:100%;
}

main {
	padding-left:1em;
	padding-right:1em;
}

.row {
	margin:1em -1em;
	display:flex;
	flex-wrap:wrap;

	+ .row {
		margin-top:0;
	}
}

.col {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 100%;
	width: 100%;
	padding: 0 1em;
	float: left;

	@media (min-width:$screen-md-min) {
		@for $i from 1 through 12 {
			&--#{$i} {
				width: 100% / 12 * $i;
				flex-basis: calc(#{100% / 12 * $i} - 2em);
			}
		}
	}
}

footer {
	background: $yellow--faded;
	border-top: .2em solid $yellow--faded;
	font-size: .85em;
	padding-bottom:2em;

	@media (min-width:$screen-md-min) {
		padding: 0 9999em 2em;
		margin: 0 -9999em;
	}
}

button:not([class]), .button {
	background:$brand-color;
	color:#fff;
	font:inherit;
	padding:.5em 1em;
	border:darken($brand-color, 10%);
}

button.link {
	display:inline;
	border:0;
	outline:0;
	padding:0;
	@extend a;
}

fieldset {
	background:$blue;
	border:0;
}

label {
	margin:1em 0 .5em;
}

input {
	border: 1px solid rgba(0,0,0,.25);
	padding: .5em 1em;
	font: inherit;
	background: #fff;
	margin: 1em 0 .5em;
}

.person {
	flex-grow:1;
	min-width:250px;
	overflow:hidden;
	padding:1em;

}

.person__img {
	width: 25%;
	float: right;
}

.person__name {
	margin-bottom:0;
}