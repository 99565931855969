// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

html {
	font-size:1em;
}

body {
	font-family:'Open Sans', sans-serif;
	line-height:1.45;
	color:rgba(0,0,0,.9);
}

p {
	margin-bottom: 1.3em;
}

.text-right {
	text-align:right;
}

h1, h2, h3, h4 {
	margin: 1.414em 0 0.5em;
	font-weight: inherit;
	line-height: 1.2;
	font-family: 'Oswald', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	text-transform: uppercase;
	color: $brand-color;
	letter-spacing:-0.0375em;

	footer & {
		color:rgba(0,0,0,.6);
	}
}

h1 {
	margin-top: 0;
	font-size: 2.827em;

	@media (min-width:$screen-md-min) {
		font-size: 3.998em;
	}
}

h2 {
	font-size: 1.999em;

	@media (min-width:$screen-md-min) {
		font-size: 2.827em;
	}
}

h3 {
	font-size: 1.414em;

	@media (min-width:$screen-md-min) {
		font-size: 1.999em;

		footer & {
			font-size: 1.3em;
		}
	}
}

h4 {
	font-size: 1em;

	@media (min-width:$screen-md-min) {
		font-size: 1.414em;

	}
}

small, .font_small {
	font-size: 0.707em;
}