﻿.issuu-list {
	display:flex;
	flex-wrap:wrap;
	margin:0;
	padding:0;
	list-style-type:none;
}

.issuu-publication {
	width: 25%;
	padding: 1em;

	.issuu-list--0 &:nth-child(-n+2) {
		width: 50%;
	}


	@media (min-width:$screen-md-min) {

		&.issuu-publication {
			width: 20%;

			.issuu-list--0 &:nth-child(-n+3) {
				width: 33%;
			}
		}
	}

	@media (min-width:$screen-lg-min) {

		&.issuu-publication.issuu-publication {
			width: calc(100% / 8);

			.issuu-list--0 &:nth-child(-n+4) {
				width: 25%;
			}

			.issuu-list--0 &:nth-child(-n+10):not(:nth-child(-n+4)) {
				width: calc(100% / 6);
			}
		}
	}
}